// ==========================================================================================================
// ==== VIEW WATCHLIST ======================================================================================
// ==========================================================================================================

/**
 * @description Methods that need to be called on every view
 */

modulejs.define(
	// name
	'view.watchlist',
	// dependencies
	[
		'jQuery',
		'page',
		'shared.helper',
		'service.config',
		'service.state',
		'controller.ajax',
		'controller.meta',
		'controller.watchlist',
		'controller.pageChange',
		'controller.lazyImage',
		'module.closePage'
	],
	// constructor
	function ( $, page, Helper, Config, State, Ajax, Meta, Watchlist, PageChange, LazyImage, ClosePage ) {

		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'Watchlist';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'View',
			URL = '/merkliste',
			LOAD_COUNT = 12;

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',

			_watchlist = false,

			_selector = '[view=watchlist]',

			_selector_list = '.watchlist-teaser',

			_selector_teaser = '.teaser',
			_selector_teaser_grid = _selector_list + ' > .row > .columns',
			_selector_container = '[role=main]',
			_selector_teaser_container = '.watchlist-teaser > .row',
			_selector_head_counter = '.page-title .counter',
			
			_selector_load_more = '#btn_load_more',
			_selector_remove_button = '.btn-remove',
			_selector_generate_button = '#btn_generate_id',
			_selector_code_display = '#code_display',

			_selector_share = '.watchlist-share',
			_selector_share_info = '.watchlist-share-info',
			
			_selector_sub = '.page-subtitle.watchlist-filled',
			_selector_sub_empty = '.page-subtitle.watchlist-empty',

			_selector_code_input = '#watchlist_code',
			_selector_code_submit = '#watchlist_code_submit',

			_element = $(),
			_element_more_button = [],
			_element_generate_button = [],
			_element_code_display = [],

			_element_code_input = [],
			_element_code_submit = [],

			_element_list = [],
			_element_teaser_list = [],

			_load_section = [],

			_loaded = 0,

			_initialized = false;
		
		// PRIVATE METHODS ////////////////////////////////////////////////////
		var View = {};

		View.log = function ( string ) {
			console.log( _log + string );
		};

		View.init = function () {

			View.log('init');

			_loaded = 0;

			_element = $(_selector);
			_element_list = $(_selector_list, _element);
			
			_element_more_button = $(_selector_load_more, _element);
			_element_teaser_list = $(_selector_teaser_container, _element);

			_element_generate_button = $(_selector_generate_button);
			_element_code_display = $(_selector_code_display);

			var watchlist = Watchlist.get();

			if (watchlist) {
				_watchlist = watchlist
			} else {
				_watchlist = false;
			}

			if (!_watchlist || _watchlist.list.length == 0) {
				$(_selector_share,_element).hide();
				$(_selector_share_info,_element).hide();
				$(_selector_sub, _element).hide();
				
				$(_selector_sub_empty, _element).show();
			} else {
				$(_selector_sub_empty, _element).hide();
			}

			// listen for remove button
			_element_list.on('click', _selector_remove_button, View.onRemoveListener);

			if ( !State.cache_disabled && _watchlist ) {
				_element_more_button.on('click', function (event) {

					View.loadTeaser();

					_element_more_button.hide();
				});

				_element_generate_button.on('click', function (event) {
					View.saveWatchlist();
				});

				View.updateCounter();

			} else {
				_element_more_button.hide();
			}

			ClosePage.init(_element);

			Meta.readFrom(_element);
			
			View.codeInputInit();

			_initialized = true;

		};

		View.show = function () {
			View.log('show');
			if (!_initialized) View.init();

			View.loadTeaser();

			State.current_view = name;
		};


		View.updateCounter = function () {
			// set head count
			var length = _watchlist.list.length,
				label = length + '';

			while ( label.length < 4 ) label = '0' + label;

			// write head counter
			$(_selector_head_counter, _element).html(label).show();
		};


		View.onRemoveListener = function (event) {
			
			View.log('onRemoveListener');

			var teaser = $(this).closest(_selector_teaser),
				id = teaser.attr('data-id');

			var index = Helper.indexByAttr(_watchlist.list, 0, id);

			View.log('id: ' + id + ' | index: '+index);

			if ( index !== false ) {
				_watchlist.list.splice(index,1);
				
				Watchlist.overwrite(_watchlist);
			}
			teaser.parent().remove();
			_loaded -= 1;

			View.updateCounter();

			if (_watchlist.list.length == 0) {
				$(_selector_share,_element).hide();
				$(_selector_share_info,_element).hide();
				$(_selector_sub, _element).hide();
				
				$(_selector_sub_empty, _element).show();
			}
		};


		View.load = function (ctx) {
			
			// check if data is already cached
			
			View.log( 'Route.load' );

			return $.get(ctx.path, function (data) {

				var $temp = $(data).filter('.page');

				$(_selector_container).append($temp);

				_element = $temp;
			});
				
		};


		View.loadTeaser = function () {

			View.log('load from '+_loaded+' to '+(_loaded+LOAD_COUNT));

			var section = _watchlist.list.slice(_loaded,_loaded + LOAD_COUNT);

			var watchpart = JSON.stringify({list:section});

			return Ajax.load(URL, {watchlist:watchpart }, View.onLoad);
		};


		View.onLoad = function (data) {
			// console.log(data);
			View.log('onLoad');
			//console.log(data);

			var $temp = $(data).find(_selector_teaser_grid);
			// console.log($temp);


			$temp.each(function (index) {
				_element_teaser_list.append(this);
			});

			// _element_teaser_list.append($temp);

			LazyImage.init($temp);

			_loaded += LOAD_COUNT;

			View.log('loaded: '+_loaded+' of '+_watchlist.list.length);
			

			if ( _loaded < _watchlist.list.length ) {
				_element_more_button.show();
			} else {
				_element_more_button.hide();
			}
		};


		View.saveWatchlist = function () {

			if ( _watchlist.list.length > 0 ) {
				$.post( Config.WATCHLIST_SAVE_URL, {watchlist:_watchlist}, function(data) {
					View.log('Saved watchlist');
					console.log(data);
					if ( data.success && data.id ) {
						Watchlist.saveSharedList(data.id);
						page.show( Config.WATCHLIST_SHARED_BASE_URL + data.id );
					}
				})
				.fail(function() {
					alert('failed to save watchlist');
				});
			}

		};


		View.exit = function (ctx) {
			
			if (_element.length) {
				_element_list.off('click', _selector_remove_button, View.onRemoveListener);
				_element.remove();
				_element = false;
			}

			View.codeInputExit();

			_initialized = false;


			State.last_view = name;
		};


		View.codeInputInit = function () {
			_element_code_input = $(_selector_code_input, _element);
			_element_code_submit = $(_selector_code_submit, _element);

			_element_code_input.on('focus', function () {

				$(_element_code_input).on('keyup', function (event) {
					
					if (event.keyCode == 13 ) {
						View.sendCode();
					}
					
				});
			});

			_element_code_input.on('blur', function () {
				_element_code_input.off('keyup');
			});


			_element_code_submit.on('click', function () {
				View.sendCode();
			});
		};

		View.codeInputExit = function () {
			_element_code_input.off('focus');
			_element_code_input.off('blur');
		};

		View.sendCode = function () {
			var value = _element_code_input.val();
			View.log(value);

			if (value && value.length > 0) {
				page.show( Config.WATCHLIST_SHARED_BASE_URL + value );
			}
			
		};

		
		// ROUTE CONNECTOR ////////////////////////////////////////////////////
		/**
		 * View.Routes
		 * Provide Methos for Page.js router
		 * Connect your View methods with the router
		 */
		var _routes = {

			// Load and setup any View specific data
			load: function ( ctx, next ) {
				console.groupCollapsed(TYPE+name+' | '+ctx.path);
				
				if (State.is_first_load && next) {
					State.is_first_load = false;
					next();
				} else {
					View.load(ctx).then(
						// success
						function(){
							if (next) next();
						},
						// failed
						function () {
							View.log('):||||||||||||||:( loading error ):||||||||||||||:(');
							ctx.state.error = true;
							if (next) next();
						}
					);
				}
				
			},

			// Add nodes to the Dom, start listeners, setup environment
			init: function ( ctx, next ) {
				//View.log( 'Route.init' );

				// continue
				if (next) next();
			},

			// show (fadeIn) the View;
			show: function ( ctx, next ) {
				
				if(ctx.state.error && next) {
					console.groupEnd();
					next();
				} else {
					View.show();
					console.groupEnd();
					PageChange.enter(ctx);
				}
				
			},

			// Remove nodes from the Dom, stop listeners, setup environment
			exit: function ( ctx, next ) {
				//View.log( 'Route.exit' );
				View.exit();

				PageChange.exit(ctx);

				if (next) next();
			}

		};

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			Routes: _routes
		};
 	}
 );