// ==========================================================================================================
// ==== VIEW DEFAULT ========================================================================================
// ==========================================================================================================

/**
 * @description Methods that need to be called on every view
 */

modulejs.define(
    // name
    'view.default',
    // dependencies
    [
        'jQuery',
        'TweenMax',
        'service.state',
        'controller.meta',
        'module.closePage',
        'controller.pageChange'
    ],
    // constructor
    function ($, TweenMax, State, Meta, ClosePage, PageChange) {

        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Default';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'View';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _initialized = false,
            _selector = '.page',
            _selector_container = '[role=main]',

            _element = $(),
            _element_container = $();


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var View = {};

        View.log = function (string) {
            console.log(_log + string);
        };

        View.init = function () {
            _element = $(_selector);
            _element_container = $(_selector_container);

            _initialized = true;

            Meta.readFrom(_element);

            ClosePage.init(_selector_container);
        };

        View.show = function (ctx) {
            View.log('show');

            var deferred = $.Deferred();

            if (!_initialized) View.init();

            PageChange.startTransition();

            TweenMax.to(_element, 0.360, {
                y: '0%', ease: Power2.easeOut, onComplete: function () {
                    deferred.resolve();
                    PageChange.stopTransition();
                }
            });

            if (ctx.params.topic && ctx.params.topic.length > 0) {
                State.partner_filter_topic = ctx.params.topic;
            }

            State.current_view = name;

            return deferred.promise();
        };

        View.load = function (ctx) {

            // check if data is already cached

            View.log('Route.load');

            return $.get(ctx.path, function (data) {

                var $temp = $(data).filter('.page');

                $(_selector_container).append($temp);

                _element = $temp;

                var h = $(window).height();
                TweenMax.set(_element, {y: h + 'px', ease: Power2.easeOut});

            });

        };


        View.exit = function (ctx) {

            var deferred = $.Deferred();

            if (_element.length) {

                TweenMax.to(_element, 0.36, {
                    y: '100%', onComplete: function () {
                        ClosePage.exit();
                        _element.remove();
                        deferred.resolve();
                    }
                });
            }

            if (ctx.params.topic && ctx.params.topic.length > 0) {
                State.partner_filter_topic = false;
            }

            State.last_view = name;

            return deferred.promise();
        };


        // ROUTE CONNECTOR ////////////////////////////////////////////////////
        /**
         * View.Routes
         * Provide Methos for Page.js router
         * Connect your View methods with the router
         */
        var _routes = {

            // Load and setup any View specific data
            load: function (ctx, next) {
                console.groupCollapsed(TYPE + name + ' | ' + ctx.path);

                if (State.is_first_load && next) {
                    State.is_first_load = false;
                    next();
                } else {
                    View.load(ctx).then(function () {
                        if (next) next();
                    });
                }

            },

            // Add nodes to the Dom, start listeners, setup environment
            init: function (ctx, next) {
                //View.log( 'Route.init' );
                View.init();
                // continue
                if (next) next();
            },

            // show (fadeIn) the View;
            show: function (ctx, next) {

                View.show(ctx).then(function () {
                    PageChange.enter(ctx);
                });

                console.groupEnd();

            },

            // Remove nodes from the Dom, stop listeners, setup environment
            exit: function (ctx, next) {
                //View.log( 'Route.exit' );
                PageChange.exit(ctx);

                View.exit(ctx).then(function () {
                    if (next) next();
                });
            }

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            Routes: _routes
        };
    }
);