// ==========================================================================================================
// ==== ARTICLE CONTROLLER ==================================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'controller.article',
    // dependencies
    [
        'jQuery',
        'TweenMax',
        'service.state',
        'shared.helper',
        'controller.ajax',
        'controller.lazyImage',
        'controller.watchlist',

        'module.carousel',
        'module.video',
        'module.photoswipe',
        'module.overscroll'
    ],
    // constructor
    function ($, TweenMax, State, Helper, Ajax, LazyImage, Watchlist, Carousel, Video, PhotoSwipeController, Overscroll) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Article';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ';

        var _settings = {
                selector: {
                    element: '[view=article]',
                    default_container: 'body',
                    panel: '.panel',
                    wrapper: '.panel > .wrapper',
                    headline: '[article-module=headline]',
                    photoswipe_item: '.slick-slide:not(.slick-cloned) .pswp-item',
                    watchlist_button: '.watchlist-trigger',
                    glossary_link: '.glossarlink',
                    back_to_top: '.article-back-to-top a'
                },

                class: {
                    animate_out: 'animate-out'
                },

                element: false,
                element_container: false,
                element_placeholder: false,
                element_wrapper: false,
                element_panel: false,
                element_headline: false,
                element_back_to_top: false,

                load_deferred: false,

                class_placeholder: 'placeholder',

                overscroll: false,

                state: {
                    minimized: false,
                    initialized: false,
                    stuck: false
                },

                data: {
                    topic: false,
                    id: false
                }
            },
            _options = {
                element: false,
                container: false
            };

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            var now = new Date(Date.now());
            console.log(_log, string, now.toLocaleTimeString() + '.' + now.getMilliseconds());
        };


        // article constructor
        var Article = function (options) {

            Controller.log('instantiated');

            this.settings = $.extend(true, {}, _settings);
            this.galleryCache = [];

            options = options || {};

            this.options = $.extend(true, _options, options);

            if (this.options.container && this.options.container.length) {
                this.settings.element_container = $(this.options.container);
            }

            // initialized with a dom element
            if (this.options.element) {
                this.init();
            }
        };

        Article.prototype.init = function () {

            Controller.log('init');

            // find container
            if (this.options.container && this.options.container.length) {
                this.settings.element_container = $(this.options.container);
            } else {
                this.settings.element_container = $(this.settings.selector.default_container);
            }

            // if element not set by ajax load try finding it by options object
            if (!this.settings.element && this.options.element) {
                this.settings.element = $(this.options.element);
            }

            if (!this.settings.element) {
                console.error('Article initialized without element');
            } else {

                this.settings.element_wrapper = $(this.settings.selector.wrapper, this.settings.element);
                this.settings.element_panel = $(this.settings.selector.panel, this.settings.element);
                this.settings.element_headline = $(this.settings.selector.headline, this.settings.element);
                this.settings.element_back_to_top = $(this.settings.selector.back_to_top, this.settings.element);

                // setup placeholder for monimized state
                this.settings.element_placeholder = $('<div class="' + this.settings.class_placeholder + '" />');
                this.settings.element_placeholder.append(this.settings.element_headline.clone());

                this.settings.element.addClass('interactive');
                this.settings.state.initialized = true;


                this.settings.data.topic = this.settings.element.attr('data-article-topic');
                this.settings.data.id = this.settings.element.attr('data-article-id');

                // set panel to animation start position
                TweenMax.set(this.settings.element_panel, {y: '100%'});

                var self = this;

                this.settings.element.on('click', this.settings.selector.glossary_link, function (event) {
                    if (!State.is_glossary_route) {

                        State.article_scroll_position = {
                            id: self.getId(),
                            top: $(window).scrollTop()
                        };
                    }
                });

                this.settings.element_back_to_top.on('click', function (e) {

                    Controller.log('click');

                    if (State.is_mobile) {

                        // TweenMax.to(window, 0.7, {scrollTo: 0, ease: Power2.easeInOut});
                        // bugfix: https://greensock.com/forums/topic/15108-ios-10-scrolltoplugin/
                        TweenLite.to(window, 0.7, {scrollTo: {y:0, autoKill:false}, ease:Power2.easeInOut});

                        return false;
                    }
                })
            }
        };


        Article.prototype.show = function () {

            Controller.log('show');

            var self = this;

            if (this.settings.state.minimized) {
                this.maximize();
            }

            LazyImage.init(this.settings.element);
            Video.init(this.settings.element);
            Carousel.init(this.settings.element);
            PhotoSwipeController.init(this.settings.element, this.settings.selector.photoswipe_item, this.getId());
            
            this.settings.element_panel.css('will-change', 'transform');
            
            // scroll article window 
            TweenMax.fromTo(this.settings.element_panel, 0.560, {y: '80%'}, {
                y: '0%', ease: Power3.easeOut, onComplete: function () {

                    Controller.log('animated in');

                    self.settings.element_panel.attr('style', '');
                    self.settings.element_panel.css('will-change', 'auto');

                    // scroll to top on mobile
                    if (State.is_mobile) {

                        var top = 164;

                        if (State.is_glossary_route) {
                            top = 0;
                        }
                        else if (State.article_scroll_position.id == self.getId()) {
                            top = State.article_scroll_position.top;
                        }

                        Controller.log('set scroll top to ' + top);
                        TweenMax.to(window, 0.85, {scrollTo: top, ease: Power2.easeInOut});
                        //$(window).scrollTop(top);
                    } else {
                        // console.log('Article scrolling', State.article_scroll_position.top, self.getId(), State);
                        if (State.article_scroll_position.id == self.getId()) {
                            TweenMax.to(window, 0.5, {
                                scrollTo: State.article_scroll_position.top,
                                ease: Power2.easeInOut
                            });
                        } else {
                            $(window).trigger('scroll');
                        }

                        if (self.settings.overscroll) {
                            self.settings.overscroll.setupMetrix();
                            self.settings.overscroll.enable();
                        }

                    }
                }
            });


        };


        Article.prototype.load = function (url) {

            Controller.log('load | ' + url);
            this.settings.load_deferred = $.Deferred();

            Ajax.load(url, {module: 'article'}, (function (self) {
                return function (data) {
                    self.onLoad(data);
                }
            })(this));

            return this.settings.load_deferred;
        };


        Article.prototype.exit = function () {
            Controller.log('exit');

            var self = this;

            if (this.settings.load_deferred) {
                this.settings.load_deferred.reject();
                this.settings.load_deferred = false;
            } else {
                if (!this.settings.element.length) return;
                
                this.settings.element.addClass(this.settings.class.animate_out);

                if (this.settings.state.stuck) {
                    this.settings.element.trigger('sticky_kit:detach');
                }

                if (this.settings.overscroll) {
                    this.settings.overscroll.destroy();
                }

                if (State.is_mobile && State.is_glossary_route) {

                    TweenMax.fromTo(this.settings.element, 0.42, {x: '0%'}, {
                        x: '100%', ease: Power4.easeIn, onComplete: function () {
                            self._destroy();
                        }
                    });
                } else if (this.settings.state.minimized || State.is_mobile) {

                    var time = 0.32;
                    if (State.is_mobile) time = 0.48;

                    TweenMax.fromTo(this.settings.element_panel, time, {x: '0%'}, {
                        x: '100%', ease: Power4.easeIn, onComplete: function () {
                            self._destroy();
                        }
                    });
                } else {
                    this.overlay();
                    
                    this.settings.element_panel.css('will-change', 'transform');
                    
                    // scroll content up to invisble
                    TweenMax.fromTo(this.settings.element_panel, 0.480, {y: '0%'}, {
                        y: '-100%', ease: Power3.easeIn, onComplete: function () {
                            self._destroy();
                        }
                    });
                }

                PhotoSwipeController.destroy(this.getId());
            }

        };


        Article.prototype._destroy = function () {
            this.settings.element_back_to_top.off('click');
            if (this.settings.element.length) {
                this.settings.element.off('click');
                this.settings.element.remove();
            }
            this.settings.element = false;
        };


        Article.prototype.onLoad = function (data) {

            if (this.settings.load_deferred) {
                Controller.log('onLoad');

                console.log(this);

                var $temp = $(data).find(this.settings.selector.element);

                this.settings.element_container.prepend($temp);

                this.settings.element = $temp;

                this.init();

                this.settings.load_deferred.resolve();
                this.settings.load_deferred = false;
            }
        };


        Article.prototype.minimize = function () {
            return;
            
/*
            if (this.settings.state.minimized || !this.settings.state.initialized) return;

            Controller.log('minimize');

            var self = this,
                width = this.settings.element_panel.width(),
                height = 150; // this.settings.element_headline.outerHeight() + 100;

            if (this.settings.overscroll) {
                this.settings.overscroll.disable();
            }

            this.settings.element_panel.addClass('minimized');

            TweenMax.set(this.settings.element_wrapper, {width: width});

            TweenMax.to(this.settings.element_wrapper, 0.6, {autoAlpha: 0});
            TweenMax.to(this.settings.element_panel, 1, {
                right: 0, width: height, ease: Power2.easeInOut, onComplete: function () {

                    self.settings.element_panel.prepend(self.settings.element_placeholder);

                    TweenMax.fromTo(self.settings.element_placeholder, 0.6, {autoAlpha: 0}, {autoAlpha: 1});
                }
            });

            this.settings.state.minimized = true;
*/
        };


        Article.prototype.maximize = function () {

            if (!this.settings.state.minimized || !this.settings.state.initialized) return false;

            Controller.log('maximize');

            if (State.is_glossary_route) {
                this.settings.state.minimized = false;
                return false;
            }

            var self = this,
                width = this.settings.element_wrapper.width();

            TweenMax.set(this.settings.element_wrapper, {width: width});

            TweenMax.to(this.settings.element_wrapper, 0.6, {
                autoAlpha: 1, delay: 0.3, onComplete: function () {
                    // reset runtime styles
                    self.settings.element_wrapper.attr('style', '');
                }
            });
            var right = (State.is_tablet) ? 30 : 60;
            TweenMax.to(this.settings.element_panel, 1, {
                right: right, width: width, ease: Power2.easeInOut, onComplete: function () {
                    // reset runtime styles
                    self.settings.element_panel.attr('style', '');

                    if (self.settings.overscroll) {
                        self.settings.overscroll.enable();
                    }
                }
            });

            TweenMax.to(this.settings.element_placeholder, 0.4, {
                autoAlpha: 0, onComplete: function () {
                    self.settings.element_placeholder.detach();
                    self.settings.element_panel.removeClass('minimized');
                }
            });

            this.settings.state.minimized = false;
        };


        Article.prototype.showGallery = function (gallery, image) {

            var galleryId = gallery.split('-')[1];

            PhotoSwipeController.show(galleryId, image, this.getId());
        };

        Article.prototype.closeGallery = function () {

            PhotoSwipeController.close();
        };

        /**
         * Checks if the gallery is open
         * 
         * @returns {boolean}
         */
        Article.prototype.isGalleryOpen = function () {

            return PhotoSwipeController.isOpen();
        };


        Article.prototype.getTopic = function () {

            if (!this.settings.state.initialized) return false;

            return this.settings.data.topic;
        };

        Article.prototype.getId = function () {

            if (!this.settings.state.initialized) return false;

            return this.settings.data.id;
        };

        Article.prototype.getElement = function () {

            if (!this.settings.state.initialized) return [];

            return this.settings.element;
        };

        Article.prototype.isMinimized = function () {
            if (!this.settings.state.initialized) return false;

            return this.settings.state.minimized;
        };


        Article.prototype.overlay = function () {

            if (this.settings.element.hasClass('active')) {

                var panel = this.settings.element_panel,
                    scrollTop = $(window).scrollTop();

                if (!panel.data('topOrg')) {
                    panel.data('topOrg', parseInt(panel.css('top'), 10));
                }


                $(this.settings.element).removeClass('active');
                panel.css('top', panel.data('topOrg') - scrollTop + 'px');
            }
        };

        /* @method sticky
         * @description stick article within container uses: https://github.com/leafo/sticky-kit
         * @param container element container to stick in
         */
        Article.prototype.sticky = function (container) {
            Controller.log('sticky');

            if (State.is_mobile || this.settings.element.outerHeight() > container.outerHeight()) return false;

            this.settings.state.stuck = true;

            this.settings.element.stick_in_parent({
                'parent': container,
                'offset_top': 135 + 72 //[view=article] margin-top: -72px;
            });
        };


        Article.prototype.appendPartnerBanner = function (banner) {
            Controller.log('appendPartner');

            var self = this;

            self.settings.element_wrapper.after(banner);

            if (self.settings.overscroll) {
                Helper.requestAnimationFrame(function () {
                    self.settings.overscroll.setupMetrix();
                });
            }
        };


        Article.prototype.appendInfiniteTeaser = function (teaser) {
            Controller.log('appendInfiniteTeaser');

            var overscrollOptions = {
                element: this.settings.element_panel,
                teaser: teaser,
                topic: this.settings.data.topic
            };

            this.settings.overscroll = new Overscroll(overscrollOptions);
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return Article;
    }
);
