// ==========================================================================================================
// ==== AJAX CONTROLLER =====================================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'controller.ajax',
    // dependencies
    [
        'jQuery'
    ],
    // constructor
    function ($) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Ajax';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',
            _element = [];

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};


        Controller.log = function (string) {
            console.log(_log + string);
        };


        Controller.load = function (url, params, callback) {

            Controller.log('load | ' + url + ' | params: ' + JSON.stringify(params));

            var defer = $.Deferred();

            $.get(url, params, function (data) {
                if (callback) callback(data);
                defer.resolve(data);
            }).fail(function () {
                // Not the perfect solution but this way the server will serve a clean 404 page
                window.location.reload();
                //defer.reject();
            });

            return defer.promise();
        };


        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            load: Controller.load,
            loadPanel: Controller.loadPanel
        }
    }
);
