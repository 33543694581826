// ==========================================================================================================
// ==== VIEW SEARCH =========================================================================================
// ==========================================================================================================

/**
 * @description Methods that need to be called on every view
 */

modulejs.define(
    // name
    'view.search',
    // dependencies
    [
        'jQuery',
        'shared.helper',
        'service.state',
        'controller.meta',
        'controller.pageChange',
        'controller.lazyImage',
        'module.topicDropdown'
    ],
    // constructor
    function ($, Helper, State, Meta, PageChange, LazyImage, TopicDropdown) {

        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Search';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'View';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _initialized = false,
            _selector = '.page',
            _selector_container = '[role=main]',

            _selector_filter_topic = '#filter_topic',
            // _selector_filter_section = '#filter_section',

            _element = $(),
            _element_topic_dropdown;
        //_element_section_dropdown;


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var View = {};

        View.log = function (string) {
            console.log(_log + string);
        };

        View.init = function () {
            _element = $(_selector);

            Meta.readFrom(_element);

            _initialized = true;
        };

        View.show = function (ctx) {
            View.log('show');
            if (!_initialized) View.init();

            LazyImage.init(_element);

            _element_topic_dropdown = new TopicDropdown($(_selector_filter_topic, _element));

            State.current_view = name;

            //URL Parameter verwenden (todo Model in JavaScript direkt injezieren)
            var topic = Helper.getParameterByName('thema', ctx.path);
            var selection = Helper.getParameterByName('bereich', ctx.path);
            var count = _element.attr('data-search-count');
            count = count ? parseInt(count, 10) : 1;
            var pageNumber = _element.attr('data-search-page');
            pageNumber = pageNumber ? parseInt(pageNumber, 10) : 1;
            pageNumber = !isNaN(pageNumber) ? pageNumber : 1;
            var pageCount = _element.attr('data-search-page-count');
            pageCount = pageCount ? parseInt(pageCount, 10) : 1;
            pageCount = !isNaN(pageCount) ? pageCount : 1;

            State.current_search_topic = topic;
            State.current_search_selection = selection;
            State.current_search_count = count;
            State.current_search_page = pageNumber;
            State.current_search_page_counter = pageCount;
        };

        View.load = function (ctx) {

            // check if data is already cached

            View.log('Route.load');

            return $.get(ctx.path, function (data) {

                var $temp = $(data).filter('.page');

                $(_selector_container).append($temp);

                _element = $temp;
            });
        };


        View.exit = function (ctx) {

            if (_element.length) {
                _element.remove();

                if (State.is_search_route) {
                    State.is_search_route = false;
                }

                _element_topic_dropdown.destroy();
            }

            State.last_view = name;
        };


        // ROUTE CONNECTOR ////////////////////////////////////////////////////
        /**
         * View.Routes
         * Provide Methos for Page.js router
         * Connect your View methods with the router
         */
        var _routes = {

            // Load and setup any View specific data
            load: function (ctx, next) {
                console.groupCollapsed(TYPE + name + ' | ' + ctx.path);

                if (State.is_first_load && next) {
                    State.is_first_load = false;
                    next();
                } else {
                    View.load(ctx).then(function () {
                        if (next) next();
                    });
                }

            },

            // Add nodes to the Dom, start listeners, setup environment
            init: function (ctx, next) {
                //View.log( 'Route.init' );

                // continue
                if (next) next();
            },

            // show (fadeIn) the View;
            show: function (ctx, next) {

                View.show(ctx);

                // Search Route
                State.is_search_route = true;

                console.groupEnd();
                PageChange.enter(ctx);
            },

            // Remove nodes from the Dom, stop listeners, setup environment
            exit: function (ctx, next) {

                View.exit();
                PageChange.exit(ctx);

                if (next) next();
            }

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            Routes: _routes
        };
    }
);