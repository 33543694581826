/**
 * (c) webXells GmbH
 *
 * This computer program is the sole property of webXells GmbH
 * (http://www.webXells.com) and is protected under the German Copyright Act
 * (paragraph 69a UrhG). All rights are reserved. Making copies,
 * duplicating, modifying, using or distributing this computer program
 * in any form, without prior written consent of webXells, is
 * prohibited. Violation of copyright is punishable under the
 * German Copyright Act (paragraph 106 UrhG). Removing this copyright
 * statement is also a violation.
 *
 * @author: Christian Lahrz-Beckmann
 * @since: 15.12.2016 - 14:30
 * @version: 0.0.1
 * @project: Baunetzwissen
 * @file:
 * @description: Webtrekk Service
 */

modulejs.define(
	// name
	'service.pagedot',
	// dependencies
	[
		'jQuery',
		'service.state',
		'shared.helper',
	],
	// constructor
	function ($, State, Helper) {
		var controller = 'baunetzwissen';
		var action;
		var pageObjects = [];

		var PagedotService = {
			init: function () {
				var me = this;

				me.initEventListener();

				return true;
			},

			initEventListener: function () {
				var me = this;

				//kleine Bildergalerie im Artikel wechselt Bild
				$(window).on('slick.changed', function (event, slick, currentSlide, nextSlide) {
                    // gallery id holen
                    var _galleryNr = 0;
                    if ($(slick.$slider[0].length)) {
                        var $galleryContainer = $(slick.$slider[0]).closest('[data-gallery-id]')
                        if ($galleryContainer.length) {
                            _galleryNr = $galleryContainer.attr('data-gallery-id');
                            _galleryNr =_galleryNr.slice(_galleryNr.lastIndexOf("-") + 1)
                        }
                    }
                    
                    me.process('slick.changed', null, {
                        type: 'slick',
                        galleryId: _galleryNr,
                        galleryImage: nextSlide + 1
                    });
				});

				//Seitenwechsel tracken (bei Initialisierung wird ebenfalls ein PageChange getriggert)
				$(window).on('PageChange', function () {
					me.process();
				});
                
                // Sichtbarkeit des Sponsor Banners unterhalb des Artikels 
                $(window).on('scroll', function() {
                    var $el = $('[data-article-sponsor] [data-heinze-teaser]');
                    if (!$el.length) return;
                    
                    // 1-mal tracken, nicht mehrfach 
                    if ($el.attr('data-dwh-tracked') === '1') return;

                    if ($el.visible()) {
                        $el.attr('data-dwh-tracked', '1');
                        
                        // tracken 
                        me.process('sponsor.banner.visible', $el);
                    }
                });
                
                // KLick auf Sponsor Banners unterhalb des Artikels
                $(document).on('click', '[data-article-sponsor] [data-heinze-teaser]', function() {
                    // tracken 
                    me.process('sponsor.banner.click', $(this));
                });
                
				//Print detection
				//Quelle: https://www.tjvantoll.com/2012/06/15/detecting-print-requests-with-javascript/
				if (window.matchMedia) {
                    var printed = false;
                    var mediaQueryList = window.matchMedia('print');
                    mediaQueryList.addListener(function (mql) {
                        if (!mql.matches) return;
                        if (printed) return;
                        printed = true;
                        
                        me.trackPrint();
                        
                        // doppeltes Tracking verhinden 
                        setTimeout(function() {
                            printed = false;
                        }, 1000);
                    });
				}

				window.onbeforeprint = PagedotService.trackPrint;
			},

			trackPrint: function () {
				PagedotService.process('print');
			},

			/**
			 * Sendeprozess vorbereiten
			 */
			process: function (eventName, $el, data) {
				var me = this;
                
                // Nur Bildergalerien/Slider, Sponsor Banner (sichtbar, per Scrollen, unterhalb des Artikels) tracken 
                if (eventName !== 'print' && 
                    eventName !== 'sponsor.banner.visible' && 
                    eventName !== 'sponsor.banner.click' && 
                    (!State.current_gallery && eventName !== 'slick.changed')) return;
                
				me.buildContentGroups(eventName, $el, data);
				me.send();

				console.group('service.pagedot');
				console.log('CONTROLLER  ', controller);
				console.log('ACTION      ', action);
				console.log('PAGEOBJECTS ', pageObjects);
				console.log('STATE       ', State);
				console.groupEnd();
			},

			buildContentGroups: function (eventName, $el, data) {
				action = State.current_view;
				pageObjects = [];
                
                if (eventName === 'print') {
                    pageObjects.push({key: 'pageType', value: 'print'});
                }
                
                // Sponsor Banner unterhalb des Artikels
                if ($el && $el.length) {
                    // console.log('INTERSECTION BUILD', eventName, $el, $el.data('heinze-teaser'));
                    
                    var heinzeData = $el.data('heinze-teaser');
                    
                    if ('banner' in heinzeData) {
                        if ('sponsor.banner.visible' === eventName) {
                            pageObjects.push({key: 'adView', value: encodeURIComponent(heinzeData.banner)});
                        } else if ('sponsor.banner.click' === eventName) {
                            pageObjects.push({key: 'adKlick', value: encodeURIComponent(heinzeData.banner)});
                        }

                        // producerID eindeutig dem Werbebanner zugehörig, i.V.m adView eindeutig zuzuordnen 
                        if ('sponsor.banner.visible' === eventName || 'sponsor.banner.click' === eventName) {
                            // producerID direkt vom Banner NICHT vom Artikel verwenden  
                            if ('producerID' in heinzeData) {
                                // pageObjects.push({key: 'producerID', value: encodeURIComponent(heinzeData.producerID)});
                                // producerIdAd als eindeutige Zuordnung
                                pageObjects.push({key: 'producerIdAd', value: encodeURIComponent(heinzeData.producerID)});
                            }
                        }
                    }
                }
                
				if (State.is_topic_route) {
					controller = State.current_topic;
					
					if (State.current_section && State.current_selection) {
						action = State.current_section + '-' + State.current_selection;
					
						if (State.current_article) {
                            if (eventName !== 'print') {
                                pageObjects.push({key: 'pageType', value: 'detail'});
                            }

							var pageTitle = State.current_article;
							if (State.current_article_instance && State.current_article_instance.settings && State.current_article_instance.settings.element_headline) {
								pageTitle = State.current_article_instance.settings.element_headline.text();
							}
                            pageObjects.push({key: 'pageTitle', value: encodeURIComponent(pageTitle)});

							if (State.current_article_instance && State.current_article_instance.settings && State.current_article_instance.settings.data && State.current_article_instance.settings.data.id) {
								pageObjects.push({key: 'pageID', value: encodeURIComponent(State.current_article_instance.settings.data.id)});
							}

							if (State.current_gallery) {
								pageObjects.push({key: 'gallery', value: encodeURIComponent(State.current_gallery)});
								if (State.context.params.image) {
									pageObjects.push({key: 'galleryImage', value: encodeURIComponent(State.context.params.image)});
								}
							}
						} else {
							pageObjects.push({key: 'pageType', value: 'list'});
						}
						
                        // producerID noch nicht vorhanden 
                        // nicht für Themenpartner, sondern einzeln je Artikel gesponsorte Inhalte setzen
                        
                        var hasProducerID = false;
                        pageObjects.forEach(function(item) {
                            if (item.key === 'producerID') {
                                hasProducerID = true;
                            }
                        });
                        
                        if (!hasProducerID) {
                            var heinzeAdito = $('[data-article-sponsor-id]').attr('data-article-sponsor-id');
                            if (heinzeAdito && heinzeAdito !== '') {
                                pageObjects.push({key: 'producerID', value: encodeURIComponent(heinzeAdito)});
                            }
                        }

                        // var heinzeAdito = $('.partner-slide.slick-active').attr('data-heinze-adito');
                        // if (heinzeAdito && heinzeAdito !== '') {
                        //     pageObjects.push({key: 'producerID', value: heinzeAdito});
                        // }
					} else {
						action = 'Startseite';
						pageObjects.push({key: 'pageType', value: 'start'});
					}
				}
                
                // Slick Slider bewegt
                if (data) {
                    if (typeof data.type !== 'undefined' && data.type === 'slick') {
                        pageObjects.push({key: 'gallery', value: 'gallery-slider-' + data.galleryId});
                        pageObjects.push({key: 'galleryImage', value: data.galleryImage});
                    }
                }

				/*if (State.is_glossary_route || (State.is_topic_route && State.current_glossary_layer)) {
					controller = State.current_glossary_topic && State.current_glossary_topic !== '' ? State.current_glossary_topic : '';
					action = 'Glossar';

					pageObjects.push({key: 'pageType', value: (State.current_glossary_article ? 'detail' : 'list')});
                    pageObjects.push({key: 'listChar', value: State.current_pathname.match(/\/glossar\/(\w)\b|$/)[1]});
					if (State.current_glossary_article) {
						var pageTitle = State.current_glossary_article;
						if (State.current_article_instance && State.current_article_instance.settings && State.current_article_instance.settings.element_headline) {
							pageTitle = State.current_article_instance.settings.element_headline.text();
						}
						pageObjects.push({key: 'pageTitle', value: encodeURIComponent(pageTitle)});
                        pageObjects.push({key: 'pageID', value: State.current_article_instance.settings.data.id});
					}
                    pageObjects.push({key: 'thema', value: State.current_glossary_topic});
				}
                
                if (State.current_view === 'Home') {
                    action = 'Homepage';
                    controller = '';
                }
				
				if (State.current_view === 'Search') {
					action = 'Suche';
                    pageObjects.push({key: 'pageType', value: 'search'});
					
				    if (State.current_search_topic) {
						controller = State.current_search_topic;
					}

					var searchTerm = Helper.getParameterByName('q');
					if (searchTerm) {
                        pageObjects.push({key: 'searchTerm', value: encodeURIComponent(searchTerm)});
					}

					if (State.current_search_count) {
						pageObjects.push({key: 'resultNr', value: State.current_search_count});
					}
				}

				//Informationsseiten: Impressum, About
				if (State.current_view === 'Default') {
					var pathname = $('article.page[view]').attr('view');
					pathname = pathname === 'imprint' ? 'Impressum' : pathname;
					pathname = pathname === 'privacy' ? 'Datenschutz' : pathname;
					pathname = pathname === 'newsletter_deregister' ? 'Newsletterabmeldung' : pathname;

					action = pathname;
					pageObjects.push({key: 'pageType', value: 'info'});

					if (State.partner_filter_topic) {
						pageObjects.push({key: 'partnerTopic', value: State.partner_filter_topic});
					}
				}

				//Verlauf
				if (State.current_view === 'History') {
					action = 'Verlauf';
				    pageObjects.push({key: 'pageType', value: 'info'});
				}

				// Newsletterbestellung
				if (State.current_view === 'Newsletter') {
					action = 'Newsletterbestellung';
					if (State.context && State.context.params && State.context.params.topic && State.context.params.topic !== '') {
                        pageObjects.push({key: 'thema', value: State.context.params.topic});
                    }
				}

				//Merkliste
				if (State.current_view === 'Watchlist' || State.current_view === 'WatchlistShared') {
                    action = 'Merkliste';
				    pageObjects.push({key: 'pageType', value: 'info'});
				}*/
			},

/*
            getUrlParam: function (key) {
                if (!('URLSearchParams' in window)) return '';

                var urlParams = new URLSearchParams(window.location.search);
                return urlParams.get(key);
            },

            getSessionParam: function (key) {
                if (!('sessionStorage' in window)) return '';

                var output = '';
                try {
                    output = sessionStorage.getItem(key);
                } catch (e) {
                    
                }

                return output;
            },

            setSessionParam: function (key, value) {
                if (!('sessionStorage' in window)) return '';

                try {
                    sessionStorage.setItem(key, value);
                } catch (e) {
                    
                }
            },
*/

            /**
             * URL-Parameter context, in sessionStorage schreiben,
             * soll mitgegeben werden bei einem Visit 
             * @returns {string}
             */
/*
            getContext: function() {
			    var me = this;
			    
			    var context = '';
			    var urlContext = me.getUrlParam('context'); 
			    var sessionContext = me.getSessionParam('context');
			    // sessionContext erneuern wenn URL context vorhanden ist 
			    if (urlContext && urlContext !== '') {
			        me.setSessionParam('context', urlContext);
                    context = urlContext;
                } else {
                    context = sessionContext;
                }
			    
			    return context && context !== 'null' ? context : '';
            },

            generateSessionId: function() {
                // https://gist.github.com/gordonbrander/2230317
                return (Math.random().toString(36).substr(2, 9) + Date.now().toString(32) + Math.random().toString(36).substr(2, 9)).substr(1, 27);
            },
            
            getSessionId: function() {
                var me = this;
                // sessionId auslesen 
                var sessionId = me.getSessionParam('sessionId');
                if (sessionId && sessionId !== '') return sessionId;
                
                // generiere neue session Id 
                sessionId = me.generateSessionId();
                me.setSessionParam('sessionId', sessionId);
                return sessionId;
            },
*/
            
			send: function () {
                // ac = autoconfirm 1 
                // c = controller 
                // a = action
                // po = pageObjects
                var url = '/dwh-punkt?c=' + controller + '&a=' + action + '&po=' + JSON.stringify(pageObjects) + '&ac=1&r=' + (Math.floor(Math.random() * 10000) + 1);
				var $bnwPunkt = $('#bnw-punkt');
				if (!$bnwPunkt.length) {
                    $bnwPunkt = $('<img id="bnw-punkt" alt="" src="' + url + '" style="position: absolute; top: 101vh; left: 101vw; height: 1px; width: 1px;">');
				}
				$bnwPunkt.attr('src', url);
				$('body').append($bnwPunkt);
/*
				var me = this;
                var context = me.getContext();
                var referer = 'referrer' in document ? encodeURIComponent(document.referrer) : '';
                var sessionId = encodeURIComponent(me.getSessionId());
                var sitename = typeof window.location.pathname !== 'undefined' ? encodeURIComponent(window.location.pathname) : '';
                controller = controller !== '' ? controller : 'baunetzwissen';
                var url = 'https://www.baunetz.de/dwhpagedots/baunetzwissen/dot.php?controller=' + controller + '&action=' + action + '&po=' + JSON.stringify(pageObjects) + '&autoConfirmed=1&sitename=' + sitename + '&sessionId=' + sessionId + '&referer=' + referer + '&context=' + context + '&r=' + (Math.floor(Math.random() * 10000) + 1);
				var $bnwPunkt = $('#bnw-punkt');
				if (!$bnwPunkt.length) {
					$bnwPunkt = $('<iframe style="display: none; visibility: hidden; width: 0; height: 0; overflow: hidden;" id="bnw-punkt" border="0" height="0" width="0" scrolling="no" src=""></iframe>');
				}
				$bnwPunkt.attr('src', url);
				$('body').append($bnwPunkt);
*/
			},

		};

		return PagedotService;
	});
